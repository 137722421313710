import React from 'react';
import {ReactComponent as Delete}  from "../../../../../assets/popups/error.svg"
import {useNavigate} from "react-router-dom";
import usePopup from "../../../../helper/usePopup";
import {Bounce, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import orderServices from "../../../../services/orderServices";
const CancelProceeding = ({ setRemoveOrderID, removeOrderID, orderID, status }) => {
    const navigate = useNavigate()
    const { closeOrderPopup } = usePopup();
    const handleFirstButton = () => {
        if (setRemoveOrderID && removeOrderID) {
            let newData = { ...removeOrderID, noDisplayOrder: [...removeOrderID.noDisplayOrder, orderID] };
            setRemoveOrderID(newData);
            orderServices.changeOrderStatus(orderID, status);
        }
        closeOrderPopup();
        toast.success("Pomyślnie przerwano realizację zlecenia", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            icon:false,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
        })
    };
    const handleSecondButton = () => {
        closeOrderPopup();
    };
    return (
        <div className='abort-popup'>
            <div className="upper-part-popup">
                <Delete />
                <h3>Przerwij realizację<br/> zlecenia.</h3>
                <p>Tej operacji nie można cofnąć.</p>
            </div>
            <div className='buttons'>
                <button className="button cancel" onClick={handleSecondButton}>Anuluj</button>
                <button className="button abort" onClick={handleFirstButton}>Przerwij</button>
            </div>
        </div>
    );
}

export default CancelProceeding;
