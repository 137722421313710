import React from 'react';

const SizeElement = ({ size, isChecked, handleCheckElement }) => {
    const handleInputs = (event) => {
        const newChecked = event.target.checked;
        handleCheckElement(size, newChecked);
    };

    return (
        <li key={`li-${size.size}`}>
            <label className="real-checkbox">
                <input
                    type="checkbox"
                    value={size.size}
                    onChange={handleInputs}
                    checked={isChecked}
                    data-group-id={size.size_group}
                />
                <span className="checkmark"></span>
                {size.size}
            </label>
        </li>
    );
};

export default SizeElement;
