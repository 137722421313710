import React, {useContext, useEffect, useRef, useState} from "react";
import {shouldDisplayButton} from "../../../../../../functions/checkPermissions";
import { ReloadTableContext } from "../../../../../Router";

import { UserContext } from "../../../../../wrapper/AuthWrap";
import usePopup from "../../../../../helper/usePopup";
import Assignee from "../../../Popup/AddOrder/Assignee";

const ForwardToGraphicButton = ({ orderID, rowData }) => {
  const { user, setUser } = useContext(UserContext);
  const { reloadComponent, setReloadComponent } = useContext(ReloadTableContext);
  const [assignedPopup, setAssignedPopup] = useState({ showPopup: false, order_id: orderID });
  const buttonRef = useRef();

  const handleForwardButton = () => {
    setAssignedPopup({ showPopup: true, order_id: orderID });
  };
  const { initPopup } = usePopup();
  useEffect(() => {
    if (assignedPopup.showPopup) {
      initPopup(<Assignee setRealoadComponent={setReloadComponent} setAssignePopup={setAssignedPopup} orderID={orderID} changeStatus={rowData.status == 'correction' ? false : true}/>);
    }
  }, [assignedPopup])
  const handleDisplayButton = () => {
    if((rowData?.order_status && ['accepted_acc'].includes(rowData.order_status)) && user.permissions.includes('ecommerce_admin')){
      return 'casual'
    }else if(user.permissions.includes('ecommerce_admin')){
      return 'acc-perm';
    }else{
      return 'casual'
    }
  }
  return (
    <>
      {shouldDisplayButton('forward', rowData?.order_status,user,) !== 'Brak' && (
        <>
          <button className={`forwoard ${shouldDisplayButton('forward', rowData?.order_status,user,) === 'Szary' ? 'casual' : null}`} style={rowData.order_status === "accepted_acc" ? {backgroundColor:'#E6E6E6', pointerEvents:'none'} : null} onClick={handleForwardButton} ref={buttonRef}>
            Przekaż do grafika
          </button>
        </>
      )}
    </>
  );
};

export default ForwardToGraphicButton;
