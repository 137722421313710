import React, { useContext } from "react";
import { TableDataContext } from "../../../../Router";
import TakeOverButton from "./buttons/TakeOverButton";
import EditButton from "./buttons/EditButton";
import DuplicateButton from "./buttons/DuplicateButton";
import RemoveButton from "./buttons/RemoveButton";
import AddCorectionButton from "./buttons/AddCorrectionButton";
import AcceptButton from "./buttons/AcceptButton";
import AddCounterBtn from "./buttons/AddCounter";
import DownloadButton from "./buttons/DownloadButton";
import FormatButton from "./buttons/FormatButton";
import ForwardToGraphicButton from "./buttons/ForwardToGraphicButton";
import RestoreProjectButton from "./buttons/RestoreProjectButton";
import ObserveButton from "./buttons/ObserveButton";
import {UserContext} from "../../../../wrapper/AuthWrap";

const CollapseActions = ({ orderID, data, rowData, variantID, status, variantsData }) => {
  const { tableContext, setTableContext } = useContext(TableDataContext);
  const { user, setUser } = useContext(UserContext);
  let buttons = {
    in_progress: [
      <AddCorectionButton isReformat={rowData?.is_reformat} rowData={rowData} orderID={orderID} key={`actionbutton-1`} />,
      <AcceptButton orderID={orderID} rowData={rowData} variantsData={variantsData} key={`actionbutton-2`} />,
      <ForwardToGraphicButton orderID={orderID} key={`actionbutton-4`} rowData={rowData}/>,
      <DuplicateButton orderID={orderID} status={"finished"} rowData={rowData} key={`actionbutton-5`} />,
      <EditButton orderID={orderID} rowData={rowData} type={`draft`} key={`actionbutton-2`} />,
      <RemoveButton orderID={orderID} key={`actionbutton-6`} rowData={rowData}/>,
      <ObserveButton orderID={orderID} rowData={rowData} key={`actionbutton-7`}/>
    ],
    draft: [
      // <AddCorectionButton isReformat={rowData.is_reformat} orderID={orderID} key={`actionbutton-1`} />,
      <EditButton orderID={orderID} rowData={rowData} type={`draft`} key={`actionbutton-2`} />,
      <DuplicateButton orderID={orderID} status={"draft"} rowData={rowData} key={`actionbutton-3`} />,
      <RemoveButton orderID={orderID} key={`actionbutton-4`} rowData={rowData}/>,
    ],
    finished: [
      <RestoreProjectButton orderID={orderID} key={`actionbutton-1`} rowData={rowData}/>,
      <AddCounterBtn isCounter={rowData?.is_counter} orderID={orderID} rowData={rowData} key={`actionbutton-2`} />,
      <DownloadButton orderID={orderID} key={`actionbutton-12`} rowData={rowData} />,
      <DuplicateButton orderID={orderID} status={"finished"} rowData={rowData} key={`actionbutton-3`} />,
      <FormatButton order_name={data.name} orderID={orderID} rowData={rowData} isReformat={rowData?.is_reformat} key={`actionbutton-4`} variantID={variantID}/>,
      <RemoveButton orderID={orderID} key={`actionbutton-5`} rowData={rowData}/>,
    ],
    new: [
      <TakeOverButton orderID={orderID} rowData={rowData} key={`actionbutton-1`} />,
      <EditButton orderID={orderID} rowData={rowData} key={`actionbutton-2`} />,
      <DuplicateButton orderID={orderID} status={"new"} rowData={rowData}  key={`actionbutton-3`} />,
      <RemoveButton orderID={orderID} key={`actionbutton-4`} rowData={rowData}/>,
    ],
  };

  return status ?  <div className="actions">{buttons[status]}</div> :  <div className="actions">{buttons[tableContext.tableType]}</div>;
};

export default CollapseActions;
