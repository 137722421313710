import React, { useEffect, useState } from "react";
import Slider from "../../../Table/Slider";
import TeamProductViewBox from "./TeamProductViewBox";

const TeamProductView = ({ productsData }) => {
  const [productSlideList, setProductsSlideList] = useState(null);

  useEffect(() => {
    let productsList = productsData.products.map((data, index) => <TeamProductViewBox data={data} key={`product-slide-${index}`} />);

    setProductsSlideList(productsList);
  }, [productsData]);

  return (
    <div className="products">
      <div className="products-wrapper">
        <Slider
          spaceBetween={0}
          slidesPerView={'auto'}
          slides={productSlideList}
          hasCustomNavigation={true}
        />
      </div>
    </div>
  );
};

export default TeamProductView;
