import React from 'react';
import {ReactComponent as Info}  from "../../../../../assets/popups/info.svg"
import {useNavigate} from "react-router-dom";
import usePopup from "../../../../helper/usePopup";
import 'react-toastify/dist/ReactToastify.css';
const FillMissingInputs = () => {
    const navigate = useNavigate()
    const { closeOrderPopup } = usePopup();
    return (
        <div className='fill-popup'>
            <div className="upper-part-popup">
                <Info />
                <h3>Uzupełnij brakujące pola, aby kontynuować</h3>
            </div>
            <div className='single-button'>
                <button className="button accept" onClick={()=> closeOrderPopup()}>Ok</button>
            </div>
        </div>
    );
}

export default FillMissingInputs;
