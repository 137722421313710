import React, { useContext, useState } from "react";
import orderServices from "../../../../../services/orderServices";
import { UserContext } from "../../../../../wrapper/AuthWrap";
import {shouldDisplayButton} from "../../../../../../functions/checkPermissions";
import Loader from "../../../../globalElements/Loader/Loader";
import LOADER_TYPE from "../../../../globalElements/Loader/LOADER_TYPE";
import BOX_INFO_ENUMS from "../../../Popup/ENUMS/BOX_INFO_ENUMS";
import POPUP_TYPES from "../../../Popup/ENUMS/POPUP_TYPES";
import { ReloadTableContext } from "../../../../../Router";

import usePopup from "../../../../../helper/usePopup";
import TakeOver from "../../../Popup/AddOrder/TakeOver";
import SomethingWentWrong from "../../../Popup/AddOrder/SomethingWentWrong";

const TakeOverButton = ({ orderID, rowData }) => {
  const { user, setUser } = useContext(UserContext);
  const {reloadComponent, setReloadComponent} = useContext(ReloadTableContext);

  const [activeButtonLoader, setActiveButtonLoader] = useState(false);

  const [showPopup, setShowPopup] = useState(false)
  const [popupData, setPopupData] = useState({type: POPUP_TYPES.NEGATIVE, displayText: BOX_INFO_ENUMS.ERROR_MESSAGE.TAKE_OVER_ERROR})
  const { initPopup, initOrderPopup } = usePopup();
  const handleCaptureButton = () => {
    setActiveButtonLoader(true);
    orderServices
      .assignProjectToCurrentAccUser(orderID, user.pk, true)
      .then((res) => {
        initPopup(<TakeOver />)
        setReloadComponent(true);
      })
      .catch((error) => {
        initPopup(<SomethingWentWrong type={'negative'} info={'take_over_error'}/>)
        setActiveButtonLoader(false);
      });
  };
  return (
    <>
      {shouldDisplayButton('take_over', rowData?.order_status,user,) !== 'Brak' &&  (
        <>
          <button className={`capture ${shouldDisplayButton('take_over', rowData?.order_status,user,) === 'Szary' ? 'casual' : null}`} onClick={handleCaptureButton}>
            {activeButtonLoader ? <Loader type={LOADER_TYPE.ACTION} startAnimation={true} width={15} height={15}/> : "Przejmij projekt"}
          </button>
        </>
      )}
    </>
  );
};

export default TakeOverButton;
