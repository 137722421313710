import React from 'react';
import {ReactComponent as Success}  from "../../../../../assets/popups/success.svg"
import usePopup from "../../../../helper/usePopup";
import 'react-toastify/dist/ReactToastify.css';
const OrderAccepted = ({}) => {
    const { closeOrderPopup } = usePopup();
    const handleBackToDashboard = () => {
        closeOrderPopup();
        window.location.reload();
    }
    return (
        <div className='send-popup'>
            <div className="upper-part-popup">
                <Success />
                <h3>Pomyślnie przywrócono <br/>projekt</h3>
            </div>
            <div className='single-button'>
                <button className="button accept" onClick={handleBackToDashboard}>OK</button>
            </div>
        </div>
    );
}

export default OrderAccepted;
