import React, { useContext } from "react";
import { MainAppContext } from "../../MainAppComponent";

const BackgroundShadow = ({setShowPopup, setAssignePopup,setShowImage, setShow, setReloadComponent, handleShadow}) => {
  const { isShadowActive, setShadowActive } = useContext(MainAppContext);

  const handleBgShadow = () => {
      if(handleShadow){
        setShadowActive(false);

        if (setShowPopup) {
          setShowPopup({ active: false, idValue: "undefined" });
        }

        if (setAssignePopup) {
          setAssignePopup({ showPopup: false, order_id: null });
        }

        if (setShow) {
          setShow(false);
          if(setShowImage) {
            setShowImage(false);
          }
        }

        if(setReloadComponent) setReloadComponent(true);

        let visibleElements = document.querySelectorAll(".visible");
        let activeElements = document.querySelectorAll(".active");
        let blurElements = document.querySelectorAll(".blur");

        visibleElements.forEach((el) => el.classList.remove("visible"));
        activeElements.forEach((el) => el.classList.remove("active"));
        blurElements.forEach((el) => el.classList.remove("blur"));
      }




  };

  return <div className={"bg-shadow active"} onClick={handleBgShadow}></div>;
};

export default BackgroundShadow;
