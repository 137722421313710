import React, { useRef, useState, useEffect } from "react";
import usePopup from "../../../helper/usePopup";
import logotypeServices from "../../../services/logotypeServices";
import Dropdown from "../../globalElements/Dropdown/Dropdown";
import { ReactComponent as Icon } from "../../../../assets/logotype-pupu-icon.svg";
import CannotLoadProducts from "./AddOrder/CannotLoadProducts";

const LogoTypeChooserPopup = ({ saveData, removeData, initialData }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [logotypeList, setLogotypeList] = useState([]);
  const [allowRemove, setAllowRemove] = useState(false);

  const [logotypeData, setLogotypeData] = useState({});

  const { closePopup, initPopup } = usePopup();
  const inputRef = useRef();

  useEffect(() => {
    if (initialData) {
      setLogotypeData({
        ...logotypeData,
        id: initialData.id,
        name: initialData.name,
        url: initialData.url,
        type: initialData.type,
      });

      if (initialData.id && initialData.name && initialData.url) setAllowRemove(true);
    }
  }, [initialData]);

  const handleInput = (e) => {
    if (e.target.value !== "") {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }

    setLogotypeData({ ...logotypeData, name: e.target.dataset.nameContent });
    getLogotypList(e.target.value);
  };

  const getLogotypList = (name) => {
    setTimeout(() => {
      if (name) {
        logotypeServices
          .getLogotypList(name)
          .then((res) => {
            let data = res.data;

            let logotypeArr = data.map((el) => {
              return { value: el.url, displayName: el.name, groupId: el.id };
            });

            setLogotypeList(logotypeArr);
          })
          .catch((error) => {
            console.error(error);
            initPopup(<CannotLoadProducts info={"product_list"} />);
          });
      }
    }, 200);
  };

  const handleListElement = (e) => {
    setShowDropdown(false);
    logotypeServices.getLogotypeImage(e.target.dataset.groupId).then((res) => {
      setLogotypeData({
        ...logotypeData,
        id: e.target.dataset.groupId,
        name: e.target.dataset.nameContent,
        url: `${process.env.REACT_APP_MEDIA_URL}${res.width && res.height ? res.image : "/static/products/no-photos.jpg"}`, // if widht and height are equal null there is no photo
      });
    });
  };

  const handleSaveButton = () => {
    saveData(logotypeData);
    closePopup();
  };

  const handleRemoveButton = () => {
    removeData(logotypeData.type);
    closePopup();
  };

  return (
    <div className="outer-add-logotype-popup">
      <div className="add-logotype-popup">
        <div className="title-section">
          <div className="icon">
            <Icon />
          </div>
          <h3>
            Dodaj, lub wyszukaj
            <br />
            logotyp
          </h3>
        </div>
        <div className="fields">
          <div className={"index"}>
            <input
              placeholder="Wpisz nazwę lub ID pliku"
              onChange={handleInput}
              className="input-field"
              ref={inputRef}
              value={logotypeData.name}
            ></input>
            {showDropdown && (
              <Dropdown
                data={logotypeList}
                handleListElement={handleListElement}
                displayCheckbox={false}
                setShowDropdown={setShowDropdown}
                showDropdown={showDropdown}
                showCheckbox={false}
              />
            )}
          </div>
          <div className={`show-field`}>
            {logotypeData.url && (
              <figure>
                <img src={logotypeData.url}></img>
              </figure>
            )}
          </div>
          <div className="buttons">
            <button className="button cancel" onClick={closePopup}>
              Anuluj
            </button>
            {logotypeData.name != "" && (
              <button className="button accept" onClick={handleSaveButton}>
                Dodaj
              </button>
            )}

            {/* {allowRemove && <button className="remove" onClick={handleRemoveButton}>Usuń</button>} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoTypeChooserPopup;
