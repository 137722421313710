import React from "react";
import TextBox from "./sectionsElements/TextBox";
import {ReactComponent as BriefIcon} from  "../../../../assets/reformats/brief.svg";
import {ReactComponent as CorrectionIcon} from "../../../../assets/reformats/korekta.svg";

const boxesList = [
  { logo: <BriefIcon />, title: "Brief", subtitle: "Wpisz tutaj dodatkowe informacje", type: "brief" },
  { logo: <CorrectionIcon />, title: "Korekta", subtitle: "Wpisz tutaj dodatkowe informacje", type: "correction" },
];

const TextInputBoxes = ({ reformatData, setReformatData }) => {
  const handleSaveData = (newData, type) => {
    if (type == "brief") setReformatData({ ...reformatData, brief: newData });
    if (type == "correction") setReformatData({ ...reformatData, correction: newData });
  };

  const handleResetData = (type) => {
    if (type == "brief") setReformatData({ ...reformatData, brief: reformatData.brief != null ? null : '' });
    if (type == "correction") setReformatData({ ...reformatData, correction: reformatData.correction != null ? null : '' });
  };

  return (
    <>
    {boxesList.map((box) => {
        return (
          <TextBox
            title={box.title}
            logo={box.logo}
            subtitle={box.subtitle}
            type={box.type}
            handleSaveData={handleSaveData}
            handleResetData={handleResetData}
            value={reformatData[box.type]}
          />
        );
      })}
    </>
  );
};

export default TextInputBoxes;
