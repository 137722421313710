import React, { useRef, useState} from "react";
import TextEditor from "../../OrderBoxFields/TextEditor";


const TextBox = ({logo, title, subtitle, type, value, handleSaveData, handleResetData}) => {
    const [disabled, setDisabled] = useState(false);

  const saveData = (newData) =>{
    handleSaveData(newData, type);
  }

  const handleCheckbox = () =>{
    handleResetData(type)
      setDisabled(prevState => !prevState)
  }
    const boxRef = useRef();
  const [isFocused, setIsFocused] = useState(false);
  return (
    <div className={`text-box ${value == null ? 'hidden' : ''}`} ref={boxRef}>
      <div className="text-box__header">
        {logo}
        <h3 className="text-box__title">{title || ''}</h3>
        {/*<span className="text-box__subtitle">— {subtitle || ''}</span>*/}
        <div className="text-box__hide-box">
            <label className="checkbox" onClick={handleCheckbox}>
                <div className="switch-container">
                    <div className={`switch ${disabled ? 'off' : 'on'}`}>
                        <div className="switch-button"/>
                    </div>
                </div>
            </label>
        </div>
      </div>
        <div className="text-box__content">
            <div className="text-box__text-area">
                <TextEditor
                    editorHeight={260}
                    initialValue={value}
                    saveData={saveData}
                    placeholder={subtitle}
                    onFocus={()=>setIsFocused(true)}
                    onBlur={()=>setIsFocused(false)}
                    style = {{
                        border:isFocused ? '1px solid #1ab394': '1px solid #E4E5E6',
                        width: '100%',
                        height: '260px',
                        background: '#fff',
                          borderRadius: '5px',
                          padding : '5px 10px',
                          fontFamily: `"Montserrat", sans-serif`,
                          fontSize: `12px`
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TextBox;
