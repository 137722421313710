import React, {useEffect, useState} from 'react';

const OrderHistory = ({data}) => {
    const [history, setHistory] = useState([]);
    useEffect(() => {
        let temp = Object.keys(data.history).map((key) => data.history[key]);
        setHistory(temp);
    }, []);
    return (
        <div className="order-history">
            <h3>Historia zmian</h3>
            <div className='history-contents'>
                {history.map((item,index) => {
                    return (
                        <div className='history-element' key={index}>
                            <span>{new Date(item.modified_at).toLocaleDateString()}{' '}{new Date(item.modified_at).toLocaleTimeString()}</span>
                            <span>ACC:{item.managed_by}</span>
                            <span>Klient:{item.client_managed_by}</span>
                            <span>Grafik:{item.assigned_to}</span>
                            <span>Reformat:{item.is_reformat ? "Tak" : "Nie"}</span>
                            <span>Licznik:{item.is_counter ? "Tak" : "Nie"}</span>
                            <span>Przywrócone:{item.is_restored ? "Tak" : "Nie"}</span>
                            <span>Status:{item.status}</span>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default OrderHistory;
