import React, { useEffect, useState, useContext } from "react";
import AddCampaignAction from "./AddCmpaignAction";
import Dropdown from "../globalElements/Dropdown/Dropdown";
import { MainAppContext } from "../../MainAppComponent";
/**
 * CampaignDropdownContainer - visual component
 *
 * that component required declaration of all fields
 */

const CampaignDropdownContainer = ({
  blockEdit,
  dropDownArea,
  validate,
  campaignValue,
  handleInput,
  inputRef,
  dropdownRef,
  handleAddButton,
  noSourceError,
  displayDropdownData,
  handleListElement,
  showDopdown,
  setShowDropdown
    ,reloadComponent
}) => {
  const [data, setData] = useState([]);
  const { isShadowActive, setShadowActive } = useContext(MainAppContext);

  useEffect(() => {
    let mapedData = displayDropdownData.map((el) => {
      return { value: el.id, displayName: el.name };
    });

    setData(mapedData);
  }, [displayDropdownData]);
  useEffect(()=>{
    setData([]);
  },[reloadComponent])
  const handleDropdown = () => {
    setShadowActive(prevState => !prevState);
    setShowDropdown(prevState => !prevState);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
        dropDownArea.current && !dropDownArea.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDopdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDopdown]);
  return (
    <div>
      <div className={blockEdit ? "dropdown-area blocked" : "dropdown-area"} ref={dropDownArea}>
        <input
            className={validate ? `campaing-input ${showDopdown ? `showbg` : ""}` : "campaing-input not-validated"}
            placeholder="Wpisz..."
            value={campaignValue}
            onChange={handleInput}
            onClick={handleDropdown}
            ref={inputRef}
        />
        <span className='presentation'>
          <span className='arrow-down' onClick={handleDropdown}/>
        </span>
        {/*<DropdownIcon className='arrow-down'  />*/}
        {showDopdown ? (
          <div className="dropdown-container" ref={dropdownRef}>
            {validate ? null : (
              <span className="error">
                *Wybierz z listy lub naciśnij <strong>dodaj kampanie</strong>
              </span>
            )}
            <Dropdown data={data} handleListElement={handleListElement} displayCheckbox={false}  campaignValue={campaignValue} showCheckbox={false}>
              <AddCampaignAction handleAddButton={handleAddButton} noSourceError={noSourceError} />
            </Dropdown>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CampaignDropdownContainer;
