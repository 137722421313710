import authHeader from '../helper/auth-header';
import { handleResponse } from '../helper/handle-response';
import getApiKey from '../helper/getApiKey';

const getCorrectionsList = async (variant_id) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/correction/${variant_id}`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getCorrectionsDetails_old = async (variant_id) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/correction/detail/${variant_id}`, requestOptions).then((r) => {
    return handleResponse(r, requestOptions).then((r) => {
      return r.json();
    });
  });
};

const getCorrectionsDetails = async (correction_id) => {
  const auth = authHeader();
  const api_key = getApiKey();
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      api_key: api_key
    }
  };

  return fetch(`${process.env.REACT_APP_API_URL}/orders/correction/detail2/${correction_id}`, requestOptions).then(
    (r) => {
      return handleResponse(r, requestOptions).then((r) => {
        return r.json();
      });
    }
  );
};

export default { getCorrectionsList, getCorrectionsDetails };
