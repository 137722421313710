/**
 * setSearchParamsInUrl - update params on every state change
 * @param {*} updatedParams 
 */
export const setSearchParamsInUrl = (updatedParams) => {
  const searchParams = new URLSearchParams(window.location.search);

  // Iterate over keys and update parameter values
  Object.keys(updatedParams).forEach((key) => {
    if (key === 'external_endpoint') {
      // If we have `external endpoint`, we add the value without key to the current URL, with parameters
      const urlWithoutEndpoint = window.location.href.split('?')[0]; // remove query string
      const updatedUrl = urlWithoutEndpoint + '?' + searchParams.toString() + `&${updatedParams[key]}`; // We add endpoint as value
      window.history.replaceState(null, '', updatedUrl); // Update URL
    } else if (updatedParams[key] !== undefined) {
      searchParams.set(key, updatedParams[key]);
    }
  });

  // Update remaining URL search parameters when we don't set `external_endpoint`
  if (!updatedParams.external_endpoint) {
    window.history.replaceState(null, '', '?' + searchParams.toString());
  }
};

/**
 * buildExternalEndpoint() - build external_endpoint method, this is specific way of store data
 * @returns external_endpoint
 */

export const buildExternalEndpoint = () => {
  const searchParams = new URLSearchParams(window.location.search);
  
  const params = [];
  
  if (searchParams.get('per_user')) {
    params.push(`per_user=${searchParams.get('per_user')}`);
  }

  if (searchParams.get('designer_status')) {
    params.push(`designer_status=${searchParams.get('designer_status')}`);
  }

  if (searchParams.get('is_read')) {
    params.push(`is_read=${searchParams.get('is_read')}`);
  }

  if (searchParams.get('deadline_from')) {
    params.push(`deadline_from=${searchParams.get('deadline_from')}`);
  }

  if (searchParams.get('deadline_to')) {
    params.push(`deadline_to=${searchParams.get('deadline_to')}`);
  }
  
  return params.length > 0 ? params.join('&') : '';
};

/**
 * getRelevantParams() - get keys from params and return only relevantParam
 * @param {*} params 
 * @returns relevantParam  
 */
export const getRelevantParams = (params) => {
  const { order_id, variant_id, variant_index, ...relevantParams } = params;
  return relevantParams;
}