import React, { useRef, useContext, useEffect } from "react";
import { AddNewOrderContext } from "../../../pages/e-commerce/AddNewOrder";
import { updateUniqueFieldsData } from "../../../../functions/boxFieldsFunctions/updateGLobalBoxFieldsData";
import { getBoxFieldContent } from "../../../../functions/boxFieldsFunctions/getBoxFieldContent";
import { getUniqueFieldState } from "../../../../functions/boxFieldsFunctions/getActualBoxFieldData";
import RadioInput from "../../RadioInput";
import {ReactComponent as LanguageIcon} from "../../../../assets/raports/language.svg"

const Language = ({ value, description, actualVariant, boxFieldID, validated }) => {
  const { boxFieldsData, setBoxFieldsData } = useContext(AddNewOrderContext);
  const languageRef = useRef([]);
  const { boxSettings, setBoxSettings } = useContext(AddNewOrderContext);

  const handleLanguageChose = (e) => {
    let chosenLanguage = e.target.value;
    let initialValue = { ...getUniqueFieldState(boxFieldsData, boxFieldID, actualVariant), value: { content: e.target.value }, custom_inputs_value: chosenLanguage };
    setBoxSettings({ ...boxSettings, language: chosenLanguage });
    updateUniqueFieldsData(initialValue, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant);
  };

  useEffect(() => {
    value.box_field_dictionary.forEach((el) => {
      if (el.default_name == boxSettings.language) {
        let initialValue = { ...getUniqueFieldState(boxFieldsData, boxFieldID, actualVariant), value: { content: el.id }, custom_inputs_value: el.default_name };
        setBoxSettings({ ...boxSettings, language: el.default_name });
        updateUniqueFieldsData(initialValue, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant);
      }
    });
  }, [boxSettings.language]);

  return (
    <div className="language">
      <div className="title-section">
        <LanguageIcon />
        <h3>Język</h3>
      </div>
      <div className="input-group">
        {value.box_field_dictionary
          ? value.box_field_dictionary.map((el, index) => {
              return (
                  <RadioInput
                      language={true}
                      checked={getBoxFieldContent(boxFieldsData, boxFieldID, actualVariant, true) == el.id ? true : false}
                      type="checkbox" data-language={el.default_name} data-name={`language`} value={el.default_name ?? ""}
                      onChange={handleLanguageChose}
                      ref={(el) => languageRef.current.push(el)}
                      label={el.default_name}
                  />
              );
            })
          : null}
      </div>
      <span className="error">{!validated ? "Wybierz język zlecenia" : ""}</span>
    </div>
  );
};

export default Language;
