import React from 'react';
import {ReactComponent as Delete}  from "../../../../../assets/popups/delete.svg"
import {useNavigate} from "react-router-dom";
import usePopup from "../../../../helper/usePopup";
import {Bounce, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import imageServices from "../../../../services/imageServices";
const DeleteFile = ({orderID, imagePk, setReloadComponent}) => {
    const navigate = useNavigate()
    const { closeOrderPopup } = usePopup();
    const handleFirstButton = () => {
        imageServices
            .removeImage(orderID, imagePk)
            .then((res) => {
                if (setReloadComponent) setReloadComponent(true);
                closeOrderPopup();
                toast.success("Plik pomyślnie usunięty", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon:false,
                    progress: undefined,
                    theme: "colored",
                    transition: Bounce,
                })
            })
            .catch((error) => {
                console.error(error);
            })
    }
    const handleSecondButton = () => {
        closeOrderPopup();
    }
    return (
        <div className='abort-popup'>
            <Delete />
            <h3>Usunąć wybrany plik?</h3>
            <p>Tej operacji nie można cofnąć.</p>
            <div className='buttons'>
                <button className="button cancel" onClick={handleSecondButton}>Anuluj</button>
                <button className="button abort" onClick={handleFirstButton}>Usuń</button>
            </div>
        </div>
    );
}

export default DeleteFile;
