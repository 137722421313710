import React, { useState, useRef, useContext, useEffect } from 'react';
import { MainAppContext } from '../../../MainAppComponent';
import BackgroundShadow from '../../globalElements/BackgroundShadow';
import productsServices from '../../../services/productsServices';
import noPhotoPlaceholder from '../../../../assets/no_photo_2.svg';
import { ReactComponent as Icon } from '../../../../assets/add_product_icon.svg';
import Dropdown from '../../globalElements/Dropdown/Dropdown';
import usePopup from '../../../helper/usePopup';
import CannotLoadProducts from './AddOrder/CannotLoadProducts';
const AddProductPopup = ({ productViewData, setProductViewData, productIndex }) => {
  const [indexValue, setIndexValue] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState(false);
  const [productCodes, setProductCodes] = useState([]);
  const [viewImage, setViewImage] = useState(null);
  const [allowLoad, setAllowLoad] = useState(false);
  const [imageArr, setImageArr] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [emptyPhotoList, setEmptyPhotoList] = useState(false);
  const [productsData, setProductsData] = useState({
    external_id: null,
    ean: null,
    plu: null,
    name: null,
    quantity: 1,
    weight: null,
    width: null,
    height: null
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const { initPopup, closePopup } = usePopup();
  const popupRef = useRef();
  const buttonRef = useRef(new Array());
  const eanInputRef = useRef();
  const { setShadowActive } = useContext(MainAppContext);

  const getProductsList = (ean) => {
    setTimeout(() => {
      if (ean) {
        productsServices
          .getProductsList(ean)
          .then((res) => {
            const data = res.data;
            const eanArr = data.map((el) => {
              return { value: el.ean, displayName: el.ean };
            });

            setProductCodes(eanArr);
          })
          .catch((error) => {
            console.error(error);
            initPopup(<CannotLoadProducts info={'product_list'} />);
          });
      }
    }, 200);
  };

  useEffect(() => {
    const eanCode = productViewData[productIndex]?.ean ?? null;
    if (eanCode) {
      setProductsData(productViewData[productIndex]);
      productsServices
        .getProducts(eanCode)
        .then((res) => {
          const data = res.data[0];
          if (data) {
            const images = data.photo_ids;
            getImagesArr(images, productsData);
            if (images?.length === 0) setEmptyPhotoList(true);
          }
        })
        .catch((error) => {
          console.error(error);
          initPopup(<CannotLoadProducts info={'product_list'} />);
        });

      if (eanCode) {
        setIsEdit(true);
      }
      setIndexValue(eanCode);
    }
  }, []);

  const handleInput = (e) => {
    if (e.target.value !== '') {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }

    if (allowLoad === false) {
      setAllowLoad(true);
    }

    setIndexValue(e.target.value);
    getProductsList(e.target.value);
  };

  const getImagesArr = (images, initialProductData) => {
    const hostName =
      process.env.REACT_APP_ENV === 'local' ? process.env.REACT_APP_APP4AD_URL : 'https://' + window.location.hostname;

    const imagePromises = images?.map((id) => {
      return productsServices.getProductImage(id).then((photoRes) => {
        return { image_id: id, image_url: hostName + photoRes.data.url };
      });
    });

    Promise.all(imagePromises).then((imageArr) => {
      setImageArr(imageArr);
      if (images.length === 1 && initialProductData) {
        //do this if there is only one image in array, set up automatically image choose
        handleImageChoice(images[0], 0, initialProductData);
        setViewImage(imageArr[0].image_url);
      }
    });
  };

  const handleAddBtn = (value) => {
    if (value !== '' && value !== null) {
      setLoadingImage(true);
      setError(false);
      setEmptyPhotoList(false);
      setImageArr([]);
      productsServices
        .getProducts(value)
        .then((res) => {
          const data = res.data[0];
          if (data) {
            const images = data?.photo_ids || null;

            const initialProductData = {
              ...productsData,
              external_id: data?.id,
              ean: data?.ean,
              plu: data?.plu,
              name: data?.name,
              quantity: data?.quantity,
              weight: data?.weight
            };
            //TODO: REFACTOR getImagesArr second parameter
            if (images?.length === 1) getImagesArr(images, initialProductData);

            if (images?.length > 0) {
              getImagesArr(images, initialProductData);
              setProductsData(initialProductData);
              setEmptyPhotoList(false);
            } else {
              setImageArr([]);
              setEmptyPhotoList(true);
              setViewImage(null); // ABC-290, obsluga braku zdjec, backend spodziewaa sie null
              setProductsData(initialProductData);
            }

            setLoadingImage(false);
          } else {
            throw new Error('external_id error');
          }
        })
        .catch((error) => {
          console.error(error);
          initPopup(<CannotLoadProducts info={'product_list'} />);
        });
    }
  };

  const handleSaveBtn = () => {
    if (imageArr?.length > 1 && !viewImage) {
      setError(true);
      return;
    }

    const productViewDataCopy = [...productViewData];
    const newProductDataObj = {
      ...productsData,
      // img_src: getFileBaseName(viewImage).includes("no-photos") ? null : viewImage,
      img_src: viewImage,
      ean: indexValue,
      // photos: [{url: getFileBaseName(viewImage).includes("no-photos") ? null : viewImage, external_id: productsData.external_id}],
      photos: [{ url: viewImage, external_id: productsData.external_id }]
    };

    if (isEdit) {
      productViewDataCopy[productIndex] = newProductDataObj;
    } else {
      productViewDataCopy.push(newProductDataObj);
    }

    setProductViewData(productViewDataCopy);
    closePopup();
    setShadowActive(false);
  };

  const handleImageChoice = (imageID, index, initialProductData) => {
    productsServices
      .getProductProperty(imageID)
      .then((res) => {
        setProductsData({
          ...initialProductData,
          image_external_id: imageID, //it is helping us in display if element is checked or not
          width: res.width,
          height: res.height
        });
      })
      .catch((error) => {
        console.error(error);
        initPopup(<CannotLoadProducts info={'product_list'} />);
      });

    if (imageArr.length && imageArr[index]?.image_url) {
      setViewImage(imageArr[index].image_url);
    }
  };

  const handleEadnCode = (e) => {
    setShowDropdown(false);
    setIndexValue(e.target.value);
    handleAddBtn(e.target.value);
  };

  return (
    <>
      <div className="outer-add-product-popup">
        <div className="add-product-popup" ref={popupRef} data-id-value={productIndex}>
          <div className="title-section">
            <div className="icon">
              <Icon />
            </div>
            <h3>
              Dodaj, lub wyszukaj <br /> produkt
            </h3>
          </div>
          <div className="fields">
            <div className={'index'} ref={eanInputRef}>
              <input
                className="input-field "
                placeholder="Wpisz nr EAN/INDEX"
                value={indexValue ?? ''}
                onChange={handleInput}
              ></input>
              {showDropdown && (
                <Dropdown
                  data={productCodes}
                  handleListElement={handleEadnCode}
                  displayCheckbox={false}
                  setShowDropdown={setShowDropdown}
                  showDropdown={showDropdown}
                  showCheckbox={false}
                />
              )}
            </div>
            {/* <div className="view"> */}
            <div className={error ? 'show-field error' : 'show-field'}>
              {loadingImage ? <span>loading...</span> : null}
              {emptyPhotoList ? (
                <figure className="no-photo">
                  <img src={noPhotoPlaceholder}></img>
                </figure>
              ) : null}
              {imageArr?.length > 1 ? (
                imageArr.map((el, index) => {
                  return (
                    <label className="custom-radio" key={`image-${index}`}>
                      <input
                        data-photo-id={el.image_id}
                        onClick={() => handleImageChoice(el.image_id, index, productsData)}
                        checked={el.image_id === productsData.image_external_id}
                        type="radio"
                      ></input>
                      <span className="custom-radio-checkmark"></span>
                      {index + 1}
                      <figure className="input-banner">
                        <img src={el.image_url}></img>
                      </figure>
                    </label>
                  );
                })
              ) : imageArr?.length === 1 ? (
                <figure className="input-banner single-product-image">
                  <img src={imageArr[0].image_url}></img>
                </figure>
              ) : null}
            </div>
            {/* </div> */}
            {error && <span className="error">Aby dodać wybierz produkt z listy</span>}
            <div className="bottom">
              <button
                ref={(el) => buttonRef.current.push(el)}
                className={'cancel view-button-active'}
                onClick={() => closePopup()}
              >
                Anuluj
              </button>
              {indexValue && (
                <button
                  ref={(el) => buttonRef.current.push(el)}
                  className={'save view-button-active'}
                  onClick={handleSaveBtn}
                >
                  {isEdit ? 'Zamień' : 'Dodaj'}
                </button>
              )}
              {/* {isEdit && (
                <button ref={(el) => buttonRef.current.push(el)} className={"remove view-button-active"} onClick={handleRemoveBtn}>
                  Usuń
                </button>
              )} TODO: usuniemy jesli chca to usunac */}
            </div>
          </div>
        </div>
      </div>
      <BackgroundShadow handleShadow={false} />
    </>
  );
};

export default AddProductPopup;
