import React, { useState, useRef } from "react";
import CalenderContainer from "../../../calenderElements/CalenderContainer";

const AddCounterCalenderDeadline = ({ setCalenderDeadline, inputValidated, deadline, placeholder }) => {
  const [chosenDate, setChosenDate] = useState("");
  const [displayData, setDisplatData] = useState("");
  const [hour, setHoure] = useState("");
  const [minute, setMinute] = useState("");
  const [error, setError] = useState(false);
  const [displayCalender, setDisplayCalender] = useState(false);

  const calendarRef = useRef();
  const hourRef = useRef();
  const minuteRef = useRef();

  const setUpDeadline = () => {
    if(chosenDate != ""){
      let chosenDeadline = `${chosenDate} ${hour !== "" ? hour : "17"}:${minute !== "" ? minute : "00"}`;
      setDisplatData(chosenDeadline);
      setCalenderDeadline(chosenDeadline);
      setDisplayCalender(false);
    }else{
      setError(true)
    }
    
  };

  return (
    <CalenderContainer
      calenderValue={displayData}
      calendarRef={calendarRef}
      setUpDeadline={setUpDeadline}
      hour={hour}
      minute={minute}
      hourRef={hourRef}
      minuteRef={minuteRef}
      error={error}
      setHoure={setHoure}
      setMinute={setMinute}
      chosenDate={chosenDate}
      setChosenDate={setChosenDate}
      inputValidated={!inputValidated}
      handleShadow={false}
      showTimeInput={true}
      displayCalender={displayCalender}
      setDisplayCalender={setDisplayCalender}
      disallowPosition={true}
      deadline={deadline}
      placeholder={placeholder}
      autoDateSet={true}
    />
  );
};

export default AddCounterCalenderDeadline;
