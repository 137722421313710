import React, { useState, createContext, useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import Menu from "./elements/Menu";
import Header from "./elements/Header";
import filterServices from "./services/filterServices";
import settingsService from "./services/settingsService";

import notificationBell from "../cetaMethods/notificationBell";
import hardReaload from "../cetaMethods/hardReaload";
import GlobalPopup from "./elements/e-commerce/Popup/GlobalPopup";
import icon from "../assets/version2.svg"
import * as Sentry from "@sentry/react";
import { UserContext } from "./wrapper/AuthWrap";
import AddNewPopupContainer from "./elements/e-commerce/Popup/AddNewPopupContainer";

export const MainAppContext = createContext(null)

const MainAppComponent = () => {
  const [totalRemovePopup, setTotalRemovePopup] = useState(false);
  const [showImage, setShowImage]=useState(false);
  const [isShadowActive, setShadowActive] = useState(false);
  const [usersList, setUsersList] = useState([])
  const [filterSettings, setFilterSettings] = useState(null);
  const [generalSettings, setGeneralSettings] = useState([]);
  const [intervalId, setIntervalId] = useState(null);
  const [popup, setPopup] = useState({ display: false, component: null, state: null, order:false });
  const {user} = useContext(UserContext);

  const initSentryUser = () =>{
    //init user for sentry to loggin
    Sentry.setUser({
      id: user.pk,
      email: `ID: ${user.pk}`,
    });
  }

  useEffect(() => {
    initSentryUser()
    notificationBell.initNotificationBell();
    hardReaload.initHardReload();

    filterServices.getFilterSettings().then(res=>{
      setFilterSettings(res)
      setUsersList(res.users);
    }).catch(error=>{
      console.log("GET USERS LIST ERROR");
      console.error(error);
    })
    settingsService.getSetting().then((res) => {
      setGeneralSettings(res);
    })
    .catch((error) => {
      console.log("GET BOX SETTINGS ERROR");
      console.error(error);
    });
  }, []);
  return (
    <MainAppContext.Provider
      value={{
        totalRemovePopup, setTotalRemovePopup,
        isShadowActive, setShadowActive,
        usersList, setUsersList,
        filterSettings, setFilterSettings,
        generalSettings, setGeneralSettings,
        intervalId, setIntervalId,
        popup, setPopup, showImage, setShowImage
      }}
    >
      <div className="main-app-component">
        {process.env.REACT_APP_ENV === "local" ? (
          <div className="menu-section">
            <Menu />
            <div className="helpdesk-banner">
              <a href="#" target={`_blank`}></a>
            </div>
          </div>
        ) : null}

        <div className="content-section">
          {process.env.REACT_APP_ENV === "local" ? <Header /> : null}
          <GlobalPopup />
          <AddNewPopupContainer />
          <Outlet />
        </div>
      </div>
    </MainAppContext.Provider>
  );
};

export default MainAppComponent;
