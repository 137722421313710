import orderServices from "../components/services/orderServices";

const removeOrderLockOnLocationChange = async (lockID) => {
  try{
    let res = await orderServices.removeOrderLock(lockID);

    if(!res.ok){
      console.log('Remove order lock status: ', res.status);
      throw new Error('Remove order lock issue')
    }

  }catch(error){
    console.error(error);
  }
}

export default removeOrderLockOnLocationChange;