
const displayCountDownTimer = (endTime, setTimerValue) => {
    let countDownDate = new Date(endTime).getTime();

    const timerInterval = setInterval(() => {
      let currentDate = new Date().getTime();
      let timeDistance = countDownDate - currentDate;

      if (timeDistance < 0) {
        clearInterval(timerInterval);
      } else {
        let minutes = Math.floor((timeDistance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((timeDistance % (1000 * 60)) / 1000);
        
        setTimerValue(`${minutes}:${seconds}`);
      }
    }, 1000);
};

const countDownTimer = (endTime, setDisplayTimerPopoup) => {
  let countDownDate = new Date(endTime).getTime() - 180000;
  
  const timerInterval = setInterval(() => {
    let currentDate = new Date().getTime();
    let timeDistance = countDownDate - currentDate;

    if (timeDistance < 0) {
      clearInterval(timerInterval);
      setDisplayTimerPopoup(true);
    }

  }, 1000);
};

const secondCountDownTimer = (endTime, setTimerValue) => {
  let countDownDate = new Date(endTime).getTime();

  const timerInterval = setInterval(() => {
    let currentDate = new Date().getTime();
    let timeDistance = countDownDate - currentDate;

    if (timeDistance < 0) {
      clearInterval(timerInterval);
    } else {
      let minutes = Math.floor((timeDistance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((timeDistance % (1000 * 60)) / 1000);
      
      setTimerValue(`${minutes}:${seconds}`);
    }
  }, 1000);
};

const initCorrectionTimer = (endTime) => {
  let globalTimerId = null;
  const TIMER_ELEMENT_ID = 'navbar-timer';

  const cetaNavbar = process.env.REACT_APP_ENV === "local"
      ? document.querySelector("header .actions")
      : document.querySelector(".nav.navbar-top-links");

  // Sprawdź, czy timer już istnieje i usuń go
  const existingTimerElement = document.getElementById(TIMER_ELEMENT_ID);
  if (existingTimerElement) {
    existingTimerElement.remove();
    if (globalTimerId) {
      clearInterval(globalTimerId);
      globalTimerId = null;
    }
  }

  const liElement = document.createElement("li");
  liElement.id = TIMER_ELEMENT_ID; // Ustaw identyfikator dla elementu li
  const spanElement = document.createElement("span");
  const spanElement2 = document.createElement("span");
  liElement.classList.add("correction-counter")
  spanElement2.classList.add("correction-text")
  spanElement.classList.add(process.env.REACT_APP_ENV === "local" ? "correction-counter-local" : "correction-counter-staging")
  spanElement2.innerHTML = "pozostały czas na wprowadzenie korekty: "
  liElement.appendChild(spanElement2);
  liElement.appendChild(spanElement);

  setTimeout(() => {
    cetaNavbar.prepend(liElement);
  }, 1); // Opóźnienie 1ms

  const updateTimer = () => {
    let countDownDate = new Date(endTime).getTime();

    let currentDate = new Date().getTime();
      let timeDistance = countDownDate - currentDate;
  
      if (timeDistance < 0) {
        clearInterval(globalTimerId);
      } else {
        let minutes = Math.floor((timeDistance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((timeDistance % (1000 * 60)) / 1000);
        
        spanElement.textContent = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      }
  };

  updateTimer();
  globalTimerId = setInterval(updateTimer, 1000);

  // Zwróć funkcję czyszczenia, która usunie timer i zatrzyma interwał
  return () => {
    if (globalTimerId) {
      clearInterval(globalTimerId);
      globalTimerId = null;
    }
    liElement.remove();
  };
};


export default {countDownTimer, displayCountDownTimer, secondCountDownTimer, initCorrectionTimer};