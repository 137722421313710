import React, { useState, useRef, useEffect } from "react";
import { capitalizeFirstLetter } from "../../../../functions/capitalizeFirstLetter";

const SizeDropdownChooser = ({ children, chosenSizeName='' }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef();

  const handleShowDropdown = () => {
    setShowDropdown(!showDropdown);

    document.addEventListener("click", handleWindowCLick);
  };

  const handleWindowCLick = (e) => {
    //hide dropdown when use clicked element outside Size.js component
    if (
      dropdownRef.current &&
      e.target !== dropdownRef.current &&
      !dropdownRef?.current.contains(e.target)
    ) {
      setShowDropdown(false);
      document.removeEventListener("click", handleWindowCLick);
    }
  };

  useEffect(()=>{
    setShowDropdown(false);
  }, [chosenSizeName])
  
  return (
    <div className="size-chooser" ref={dropdownRef}>
        <input placeholder={'Wybierz rodzaj nośnika z listy'} value={capitalizeFirstLetter(chosenSizeName)} onClick={handleShowDropdown} className="custom-input"></input>
        <span className="arrow-down"></span>
        {showDropdown && <div className="size-list-dropdown"><ul>{children}</ul></div>}
    </div>
  );
};

export default SizeDropdownChooser;
