import React, {useContext, useEffect, useState} from 'react';
import positiveIcon from "../../../../assets/v.png";
import neutralIcon from "../../../../assets/plus.png";
import negativeIcon from "../../../../assets/x.png";
import deleteIcon from "../../../../assets/icon-negative.png";
import { useNavigate} from "react-router-dom";
import usePopup from "../../../helper/usePopup";
import {MainAppContext} from "../../../MainAppComponent";
import orderServices from "../../../services/orderServices";
const NewOderPopup = ({ type, handleSaveProject, setReloadComponent, orderID, pagetype,setRemoveOrderID, removeOrderID, removeReformat, edit, oneButton, removeAllFromVariant, variantID ,imagePk, alloweRemoveOrder, setSearchParams, removeVariant}) => {
    const [content, setContent] = useState({})
    const navigate = useNavigate();
    const { closePopup, clearTimeoutManually } = usePopup();
    const { setTotalRemovePopup } = useContext(MainAppContext);
    const chooseType = () => {
        switch (type) {
            case 'positive' :
                setContent({
                    icon: positiveIcon,
                    text_small:'zlecenie zostało',
                    text_big:'przesłane <br/> do realizacji',
                    text_center:'<strong>kliknij tutaj,</strong> <br /> aby sprawdzić jego status.',
                    button_first_text:'Powrót do dashboardu e-commerce',
                    button_second_text:'Dodaj nastepne zlecenie',
                    text_last:`Automatyczny powrót do dashboardu e-commerce nastąpi za <strong>5 sekund</strong>`
                })
                break;
            case 'neutral' :
               setContent({
                    icon: neutralIcon,
                    text_small:'na pewno chcesz',
                    text_big:'zapisać zlecenie <br/> jako szkic',
                    text_center:'',
                    button_first_text:'Zapisz jako szkic i zamknij',
                    button_second_text:'Powrót do edycji',
                    text_last:'Twojego wyboru nie będzie można cofnąć.'
                })
                break;
            case 'negative' :
                setContent({
                    icon: negativeIcon,
                    text_small:'na pewno chcesz',
                    text_big:'porzucić <br/> wprowadzanie <br/> zmian',
                    text_center:'',
                    button_first_text:'Powrót do edycji zlecenia',
                    button_second_text:'Porzuć, zamykając sesje',
                    text_last:'Twojego wyboru nie będzie można cofnąć.'
                })
                break;
            case 'delete' :
                setContent({
                    icon: deleteIcon,
                    text_small:'na pewno chcesz',
                    text_big:'anulować <br/> tworzenie <br/> zlecenia',
                    text_center:'',
                    button_first_text:'Tak',
                    button_second_text:'Nie',
                    text_last:'Twojego wyboru nie będzie można cofnąć.'
                })
                break;
            case 'remove_variant' :
                setContent({
                    icon: deleteIcon,
                    text_small:'na pewno chcesz',
                    text_big:'usunąć <br/> wybrany <br/> wariant',
                    text_center:'',
                    button_first_text:'Tak, usuń',
                    button_second_text:'Nie, zostaw',
                    text_last:'Twojego wyboru nie będzie można cofnąć.'
                })
                break;
        }
    }
    useEffect(() => {
        chooseType()
    }, [type]);
    const handleFirstButton = () => {
        switch (type){
            case 'positive':
                if(oneButton){
                    closePopup();
                    setReloadComponent(true);
                }else {
                    clearTimeoutManually();
                    navigate("/");
                    window.location.reload();
                    closePopup();
                }
                break;
            case 'neutral':
                if(handleSaveProject()){
                    navigate("/");
                    window.location.reload();
                    closePopup();
                }else{
                    closePopup();
                }
                break;
            case 'negative':
                closePopup();
                break;
            case 'delete':
                if (removeReformat) {
                    removeReformat();
                } else if(alloweRemoveOrder) {
                    orderServices.changeOrderStatus(orderID, "deleted");
                }
                setTotalRemovePopup(false);
                if(pagetype === 'add_order'){
                    navigate("/");
                    window.location.reload();
                }
                if (setRemoveOrderID && removeOrderID) {
                    let newData = { ...removeOrderID, noDisplayOrder: [...removeOrderID.noDisplayOrder, orderID] };
                    setRemoveOrderID(newData);
                }
                closePopup();
                break;
            case 'remove_variant':
                removeVariant();
                closePopup();
                break;
        }
    }
    const handleSecondButton = () => {
        switch (type){
            case 'positive':
                closePopup();
                clearTimeoutManually()
                if(setSearchParams) setSearchParams("");
                document.location.reload(true)
                break;
            case 'neutral':
                closePopup()
                break;
            case 'negative':
                closePopup();
                setTotalRemovePopup(true);
                break;
            case 'delete' :
                setTotalRemovePopup(false);
                closePopup();
                break;
            case 'remove':
                closePopup();
                break;
            default:
                closePopup();
                break;
        }
    }

    const navigateToDashboard = () =>{
        clearTimeoutManually()
        closePopup();
        if(edit){
            navigate("/inprogressprojects")
            window.location.reload();
        }else{
            navigate("/sendedprojects")
            window.location.reload();
        }
    }
 return (
   <div className={`new-order-popup ${type}`} onClick={(e) => e.stopPropagation()}>
     <div className={`popup-contents ${type}`}>
       <div className="popup-upper-part">
         <img className="icon-section" src={content?.icon}></img>
         <span className={`text-small ${type}`}>{content?.text_small}</span>
         <span className={`text-big ${type}`} dangerouslySetInnerHTML={{ __html: content?.text_big }} />
       </div>
       {content?.text_center && (
         <span
             style={{cursor:'pointer'}}
           className="text-center"
           dangerouslySetInnerHTML={{ __html: content?.text_center }}
           {...(type == "positive" ? { onClick: navigateToDashboard } : {})}
         />
       )}
       <div className="popup-lower-part">
         <div className="popup-buttons">
           <button className={`popup-button first ${type}`} onClick={handleFirstButton}>
             {content.button_first_text}
           </button>
           {!oneButton && (
             <button className={`popup-button second ${type}`} onClick={handleSecondButton}>
               {content.button_second_text}
             </button>
           )}
         </div>
         <div className="text-last-container">
           <span className={`text-last ${type}`} dangerouslySetInnerHTML={{ __html: content?.text_last }} />
         </div>
       </div>
     </div>
   </div>
 );
};

export default NewOderPopup;
