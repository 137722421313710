import React, {useState, useContext, useEffect, useRef} from "react";
import { AddNewOrderContext } from "../../../pages/e-commerce/AddNewOrder";
import { updateUniqueFieldsData } from "../../../../functions/boxFieldsFunctions/updateGLobalBoxFieldsData";
import { getUniqueFieldState } from "../../../../functions/boxFieldsFunctions/getActualBoxFieldData";
import { getHeadersFieldContent } from "../../../../functions/boxFieldsFunctions/getBoxFieldContent";

const ActionName = ({ blockEdit, boxFieldID, actualVariant, validate }) => {
  const { headerBoxesData, setHeaderBoxesData } = useContext(AddNewOrderContext);
  const [localVariant, setLocalVariant] = useState(null);
  const [renderOnce, setRenderOnce] = useState(true);
  const inputRef = useRef(null);
  const handleInput = (e) => {
    let copyInputData = { ...getUniqueFieldState(headerBoxesData, boxFieldID, actualVariant), value: { content: e.target.value } };
    updateUniqueFieldsData(copyInputData, headerBoxesData, setHeaderBoxesData, boxFieldID, actualVariant);
    if(inputRef.current.parentElement.classList.contains('not-validated')) {
      validate();
    }
  };

  useEffect(() => {
    if (renderOnce && actualVariant) {
      setLocalVariant(actualVariant);
    }
  }, [actualVariant]);

  return (
    <>
      <input ref={inputRef} className={blockEdit ? "blocked" : "active"} placeholder="Wpisz..." onChange={handleInput} value={getHeadersFieldContent(headerBoxesData, boxFieldID, actualVariant) ?? ""}></input>
    </>
  );
};

export default ActionName;
