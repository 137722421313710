/**
 * substringByLength() - method thath trim string
 *
 * example usage: CollapseRow.js, AssignedProjectBox.js
 *
 * @param {String} text - text to trim
 * @param {Number} allowedLength - max allowed length to display
 * @returns trimed string
 */


const substringByLength = (text, allowedLength) => {
  if (text && text?.length < allowedLength) {
    return text;
  }
  return text?.substring(0, allowedLength) + '...';
};

export default substringByLength;
