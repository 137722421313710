const ROLE = {
  KREACJA: "kreacja",
  DTP: "dtp",
  ACCM: "account menager",
  CLIENT: "CLIENT",
  GRAPHIC: "GRAPHIC",
};

export default {
  ROLE,
};
