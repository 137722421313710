import React from "react";

const RaportsRadioButtons = ({ title, values, layoutDirection, type,validationMessage, handleRadioListElement, chosenData, isValidated, icon }) => {

  const handleCheckBox = (value) => {
    return chosenData.includes(value.toString());
  };

  return (
    <div className="raports-value-list">
      <div className="title-section">
        {icon}
        <h3>
          {title}
        </h3>
      </div>
      <div className="radio-list">
        <ul style={{ display: "flex", flexDirection: layoutDirection }}>
          {values &&
            values.length > 0 &&
            values.map((liElement, index) => {
              return (
                <li key={`raports-radio-list-${index}`}>
                  <label className={["methodType", "language", "type"].includes(type) ? "custom-radio" : "real-checkbox"}>
                    <input
                      type={["methodType", "language", "type"].includes(type) ? "radio" : "checkbox"}
                      onChange={handleRadioListElement}
                      data-input-type={type}
                      value={liElement.id}
                      checked={handleCheckBox(liElement.id)}
                    ></input>
                    {["methodType", "language", "type"].includes(type) && <div className={"custom-radio-checkmark"}></div>}
                    <span className="custom-checkbox-text">{liElement.default_name}</span>
                  </label>
                </li>
              );
            })}
        </ul>
        {!isValidated && <span className="not-validated">{validationMessage}</span>}
      </div>
    </div>
  );
};

export default React.memo(RaportsRadioButtons);
