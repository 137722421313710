import React from "react";
import TableContextContainer from "../../elements/e-commerce/Table/TableContextContainer";
import pageLogo from "../../../assets/sended-logo.svg";
import TABLE_TYPES from "../../../globalEnums/TABLE_TYPES_ENUMS";

const SendedProjects = () => {
  let data = [
    { name: "Geek & Gorgeous_03.01.23", fromClient: "Wiktoria Czubakowska", deadline: "03.01.2023 godz. 11:15" },
    { name: "Garnier_03.01.23 kliknij i sprawdź", fromClient: "Dominika Lisak", deadline: "03.01.2023 godz. 15:30" },
    { name: "A", fromClient: "Dominika Lisak", deadline: "03.01.2023 godz. 15:30" },
    { name: "B", fromClient: "Dominika Lisak", deadline: "03.01.2023 godz. 15:30" },
    { name: "C", fromClient: "Dominika Lisak", deadline: "03.01.2023 godz. 15:30" },
  ];

  return <TableContextContainer tableType={TABLE_TYPES.NEW} data={data} pageTitle={"Projekty przesłane do agencji"} logo={pageLogo} />;
};

export default SendedProjects;
