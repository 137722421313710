import React, {useEffect, useRef, useState} from "react";
import noPhotoPlaceholder from "../../../../../../assets/no_photo_2.svg";
import { ReactComponent as Rotate} from "../../../../../../assets/has_rotation.svg";
import { ReactComponent as Plus} from "../../../../../../assets/is_enlarged.svg";
import { ReactComponent as Minus} from "../../../../../../assets/is_minified.svg";

const TeamProductViewBox = ({data}) => {
    const imageRef = useRef(null);
    const [isBigger, setIsBigger] = useState(false);
    const [isSmaller, setIsSmaller] = useState(false);
    useEffect(()=>{
        if(imageRef.current.clientWidth > 290){
            setIsBigger(true);
        }else if(imageRef.current.clientWidth < 290){
            setIsSmaller(true);
        }
    },[imageRef])
  return (
      <div className={`block ${(data.rotate !== 0 || isBigger || isSmaller) && `changed`}`} key={data.id}
           data-view={data.id}>
          <div className={data.img_src ? "photo" : "photo no-photo"}>
              <img
                  src={data.img_src || noPhotoPlaceholder}
                  data-view={data.id}
                  ref={imageRef}
                  style={{
                      width: data.display_width,
                      height: data.display_height,
                      transform: `rotate(${data.rotate ?? 0}deg)`,
                  }}
              ></img>
          </div>
          <div className='icons'>
              {data.rotate !== 0 && <Rotate/>}
              {isBigger && <Plus/>}
              {isSmaller && <Minus/>}
          </div>
      </div>
  );
};


export default TeamProductViewBox
