import React from 'react';
import noPhotoPlaceholder from '../../../../../assets/no_photo_2.svg';

const TableProductList = ({ value, hasPhoto, isCorrection, valueToCompare }) => {
  //originalWidth and originalHeight are sizes of Product.js photo div element, we use it when we are creating new order
  const originalWidth = 290;
  const originalHeight = 260;

  //newWidth and newHeight are sizes of photo div element in this component
  const newWidth = 148;
  const newHeight = 148;

  const scaleImageWidth = (originalImageWidth) => {
    const scaleX = newWidth / originalWidth;
    return originalImageWidth * scaleX;
  };

  const scaleImageHeight = (originalImageHeight) => {
    const scaleY = newHeight / originalHeight;
    return originalImageHeight * scaleY;
  };

  const isProductDifferent = () => {
    if (
      value.display_width !== valueToCompare.display_width ||
      value.display_height !== valueToCompare.display_height ||
      value.rotate !== valueToCompare.rotate ||
      value.photos[0].url !== valueToCompare.photos[0].url ||
      value.ean !== value.ean
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <a
        className={`product-reference ${hasPhoto ? '' : 'no-photo'} ${isCorrection ? 'correction' : ''} ${valueToCompare && isProductDifferent(value, valueToCompare) ? 'dif' : ''}`}
        href={`/products/${value.external_id}`}
        target={`_blank`}
      >
        {isCorrection ? (
          <div className={`photo ${value?.photos[0].url ? 'no-photo' : ''} `}>
            <img
              src={value?.photos[0].url || noPhotoPlaceholder}
              style={{
                width: value.display_width ? scaleImageWidth(value.display_width) : newWidth,
                height: value.display_height ? scaleImageHeight(value.display_height) : 'auto',
                transform: `rotate(${value.rotate ?? 0}deg)`
              }}
            ></img>
          </div>
        ) : (
          <>
            <span className="value-ean" dangerouslySetInnerHTML={{ __html: value.ean }}></span>
            <span className="size">{`${value.width}x${value.height}`}</span>
            <span className="rotation">{`${value.rotate}`}</span>
          </>
        )}
      </a>
    </>
  );
};

export default TableProductList;
