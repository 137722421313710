import React, { useContext } from 'react';
import SizeElement from './SizeElement';
import { ReformatContext } from "../../../../pages/e-commerce/Reformatting";

const SizeGroup = ({ group }) => {
    const { reformatData, setReformatData } = useContext(ReformatContext);

    // Find the group data in reformatData
    const groupData = reformatData.basic_sizes_list.find(
        (el) => el.group_name === group.size_group_name
    );

    // Compute whether all sizes are checked based on reformatData
    const allSizesChecked =
        groupData &&
        groupData.chosen_sizes &&
        group.items.every((item) => groupData.chosen_sizes.includes(item.size));

    const handleCheckElement = (size, value) => {
        const tempReformatData = { ...reformatData };
        const groupDataIndex = tempReformatData.basic_sizes_list.findIndex(
            (el) => el.group_name === group.size_group_name
        );

        if (groupDataIndex !== -1) {
            let chosen_sizes = tempReformatData.basic_sizes_list[groupDataIndex].chosen_sizes || [];
            if (value) {
                if (!chosen_sizes.includes(size.size)) {
                    chosen_sizes = [...chosen_sizes, size.size];
                }
            } else {
                chosen_sizes = chosen_sizes.filter((existingSize) => existingSize !== size.size);
            }
            tempReformatData.basic_sizes_list[groupDataIndex].chosen_sizes = chosen_sizes;
            setReformatData(tempReformatData);
        }
    };

    const handleGroupCheckboxChange = () => {
        const newGroupCheckboxChecked = !allSizesChecked;

        const tempReformatData = { ...reformatData };
        const groupDataIndex = tempReformatData.basic_sizes_list.findIndex(
            (el) => el.group_name === group.size_group_name
        );

        if (groupDataIndex !== -1) {
            if (newGroupCheckboxChecked) {
                tempReformatData.basic_sizes_list[groupDataIndex].chosen_sizes = group.items.map((item) => item.size);
            } else {
                tempReformatData.basic_sizes_list[groupDataIndex].chosen_sizes = [];
            }
            setReformatData(tempReformatData);
        }
    };

    return (
        <>
            <li key={`group-${group.size_group_name}`}>
                <label className="real-checkbox">
                    <input
                        type="checkbox"
                        onChange={handleGroupCheckboxChange}
                        checked={allSizesChecked}
                        id={group?.size_group_name}
                    />
                    <span className="checkmark"></span>
                    <label className="group-name" htmlFor={group?.size_group_name}>
                        {group?.size_group_name}
                    </label>
                </label>
            </li>
            {group?.items
                ? group.items.map((size) => (
                    <SizeElement
                        key={size.size}
                        size={size}
                        isChecked={groupData.chosen_sizes.includes(size.size)}
                        handleCheckElement={handleCheckElement}
                    />
                ))
                : null}
        </>
    );
};

export default SizeGroup;
