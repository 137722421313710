import React from 'react';
import {ReactComponent as Delete}  from "../../../../../assets/popups/delete.svg"
import {ReactComponent as Warning}  from "../../../../../assets/popups/warning.svg"
import {useNavigate} from "react-router-dom";
import usePopup from "../../../../helper/usePopup";
import {Bounce, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import orderServices from "../../../../services/orderServices";
const Abort = ({edit,setRemoveOrderID, removeOrderID, orderID, status}) => {
    const navigate = useNavigate()
    const { closeOrderPopup } = usePopup();
    const handleFirstButton = () => {
        if (setRemoveOrderID && removeOrderID && !edit) {
            let newData = { ...removeOrderID, noDisplayOrder: [...removeOrderID.noDisplayOrder, orderID] };
            setRemoveOrderID(newData);
            orderServices.changeOrderStatus(orderID, status);
        }
        closeOrderPopup();
        toast.success("Pomyślnie usunięto zlecenie", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            icon:false,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
        })
    };
    const handleSecondButton = () => {
        closeOrderPopup();
    };
    return (
        <div className='abort-popup'>
            {edit ? <Warning/> : <Delete />}
            {edit ? <h3>Czy na pewno chces usunąć <br/> wprowadzone zmiany?</h3> : <h3>Czy na pewno chcesz usunąć rozpoczęte zlecenie?</h3>}
            <p>Tej operacji nie można cofnąć.</p>
            <div className='buttons'>
                <button className="button cancel" onClick={handleSecondButton}>Anuluj</button>
                <button className="button abort" onClick={handleFirstButton}>Usuń</button>
            </div>
        </div>
    );
}

export default Abort;
