import { setSearchParamsInUrl, buildExternalEndpoint } from "./orderDataParametersReducerHelpers";

export const ORDER_DATA_PARAMETERS_ACTION_TYPE = {
  SET_STATUS: "set_status",
  SET_PAGE_SIZE: "set_page_size",
  SET_PAGINATION: "set_pagination", //page
  SET_SEARCH_BY: "set_search_by",
  SET_ORDER_BY: "set_order_by", //ordering
  SET_ORDER_ID: "set_order_id",
  SET_EXTERNAL_ENDPOINT: "set_external_endpoint",
  SET_VARIANT_ID: "set_variant_id",
  SET_SHOW_VARIANT_INDEX: "set_show_variant_index",
  ORDER_AND_VARIANT:"order_and_variant"
};

/**
 * orderDataParametersReducer() - reducer for order data search parameters
 * @param {*} state 
 * @param {*} action 
 * @returns new state
 */
export const orderDataParametersReducer = (state, action) => {
  switch (action.type) {
    case ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_STATUS: {
      setSearchParamsInUrl({ status: action.status });
      return { ...state, status: action.status };
    }
    case ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_PAGE_SIZE: {
      setSearchParamsInUrl({ page_size: action.page_size });
      return { ...state, page_size: action.page_size };
    }
    case ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_PAGINATION: {
      setSearchParamsInUrl({ page: action.page });
      return { ...state, page: action.page };
    }
    case ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_SEARCH_BY: {
      setSearchParamsInUrl({ search: action.search });
      return { ...state, search: action.search };
    }
    case ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_ORDER_BY: {
      setSearchParamsInUrl({ ordering: action.ordering });
      return { ...state, ordering: action.ordering };
    }
    case ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_ORDER_ID: {
      setSearchParamsInUrl({ order_id: action.order_id });
      return { ...state, order_id: action.order_id };
    }
    case ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_EXTERNAL_ENDPOINT: {
      setSearchParamsInUrl({ external_endpoint: action.external_endpoint });
      return { ...state, external_endpoint: action.external_endpoint };
    }
    case ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_VARIANT_ID: {
      setSearchParamsInUrl({ variant_id: action.variant_id });
      return { ...state, variant_id: action.variant_id };
    }
    case ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_SHOW_VARIANT_INDEX: {
      setSearchParamsInUrl({ variant_index: action.variant_index });
      return { ...state, variant_index: action.variant_index };
    }
    case ORDER_DATA_PARAMETERS_ACTION_TYPE.ORDER_AND_VARIANT: {
      setSearchParamsInUrl({ variant_index: action.variant_index, variant_id: action.variant_id, order_id: action.order_id });
      return { ...state, variant_index: action.variant_index, variant_id: action.variant_id, order_id: action.order_id };
    }
    default:
      throw new Error(`OrderStatusReducer: no action type: ${action.type}`);
  }
};

/**
 * initializeOrderDataParametersFromUrl() - initialize parameters with data from URL
 * @param {*} initialState 
 * @returns new state
 */

export const initializeOrderDataParametersFromUrl = (initialState) => {
  const searchParams = new URLSearchParams(window.location.search);

  return {
    status: searchParams.get('status') || initialState.status,
    page_size: parseInt(searchParams.get('page_size')) || initialState.page_size,
    page: parseInt(searchParams.get('page')) || initialState.page,
    search: searchParams.get('search') || initialState.search,
    ordering: searchParams.get('ordering') ? searchParams.get('ordering').split(',') : initialState.ordering,
    order_id: searchParams.get('order_id') || initialState.order_id,
    external_endpoint: buildExternalEndpoint() || initialState.external_endpoint, // Wywołanie funkcji tworzącej external_endpoint
    variant_id: parseInt(searchParams.get('variant_id')) || initialState.variant_id,
    variant_index: parseInt(searchParams.get('variant_index')) || initialState.variant_index,
  };
};
