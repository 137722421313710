export const contentMap = {
    positive: {
        restore: { text_big: 'Pomyślnie <br /> przywrócono <br /> projekt', text_small: 'udało się', button_first_text: 'Ok' },
        take_over_success: { text_big: 'Projekt <br/> został przypisany <br/> do Ciebie', text_small: 'udało się', button_first_text: 'Ok' },
        acc_accept:{
            text_small: 'udało się',
            text_big: 'Zaakceptowano <br/>zamówienie',
            button_first_text: 'Ok'
        },
        archive:{
            text_small: 'udało się',
            text_big: 'Pomyślnie zapisano <br/> w archiwum',
            button_first_text: 'Ok'
        },
        order_accepted:{
            text_small: 'udało się',
            text_big: 'Zlecenie <br/> zostało <br/> zaakceptowane',
            button_first_text: 'Ok'
        },
        order:{
            text_small: 'udało się',
            text_big: 'Zlecenie <br/> utworzone',
            button_first_text: 'Przejdź do listy zleceń'
        },
        stop_observing:{
            text_small: 'zrobione',
            text_big: 'to zlecenie  <br/> nie jest już <br/> obserwowane',
            button_first_text: 'Ok'
        },
        start_observing:{
            text_small: 'udało się',
            text_big: 'to zlecenie <br/> jest przez Ciebie <br/> obserwowane',
            button_first_text: 'Ok'
        },
        default: {
            text_small: 'na pewno chcesz',
            text_big: 'porzucić <br/> wprowadzanie <br/> zmian',
            text_center: '',
            button_first_text: 'Powrót do edycji zlecenia',
            button_second_text: 'Porzuć, zamykając sesje',
            text_last: 'Twojego wyboru nie będzie można cofnąć.'
        },
    },
    neutral: {
        example_case_1: {
            text_small: 'na pewno chcesz',
            text_big: 'zapisać zlecenie <br/> jako szkic',
            text_center: '',
            button_first_text: 'Zapisz jako szkic i zamknij',
            button_second_text: 'Powrót do edycji',
            text_last: 'Twojego wyboru nie będzie można cofnąć.'
        },
        // Dodaj więcej przypadków dla 'neutral' w razie potrzeby
        default: {
            text_small: 'domyślny tekst dla neutralnego typu',
            button_first_text: 'Domyślny przycisk'
        },
    },
    negative: {
        external_url: {
            text_small:'błąd',
            text_big: 'Aby przesłać pliki <br /> podaj lokalizację <br /> na serwerze DTP',
            button_first_text: 'Ok'
        },
        user_id:{
            text_small:'błąd',
            text_big: 'Nie można <br /> wczytać danych <br /> aktualnego użytkownika',
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        },
        no_file: {
            text_center: 'Wybierz pliki jakie chcesz wgrać na serwer',
            button_first_text: 'Ok'
        },
        format_list:{
            text_small:'błąd',
            text_big: 'Nie można <br /> wczytać rozmiarów <br />',
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        },
        something_wrong:{
            text_small:'błąd',
            text_big: 'Coś poszło nie tak<br />spróbuj ponownie<br />',
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        },
        data_error:{
            text_small:'błąd',
            text_big: 'NIe można wczytać danych,<br />spróbuj ponownie<br />',
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        },
        get_image:{
            text_small:'błąd',
            text_big: 'Nie można wczytać zdjęć',
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        },
        image_update:{
            text_small:'błąd',
            text_big: 'Nie można <br/> zaktualizować zdjęcia,<br/> spróbuj ponownie',
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        },
        sending_image:{
            text_small:'błąd',
            text_big: 'Nie można wysłać zdjęcia,<br/> spróbuj ponownie',
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        },
        load_data:{
            text_small:'błąd',
            text_big: 'Problem przy <br/> wczytywaniu danych,<br/> spróbuj ponownie później',
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        },
        language:{
            text_small:'błąd',
            text_big: 'Musisz wybrać <br/> język zlecenia',
            button_first_text: 'Ok'
        },
        no_files_to_download:{
            text_small:'błąd',
            text_big: 'Brak plików <br/> do pobrania',
            button_first_text: 'Ok'
        },
        save_data:{
            text_small:'błąd',
            text_big: 'Nie można wysłać <br/> zlecenia do realizacji,<br/> spróbuj ponownie',
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        },
        save_data_info:{
            text_small:'błąd',
            text_big: 'Aby wykonać akcję <br/> uzupełnij <br/> zaznaczone pola',
            button_first_text: 'Ok'
        },
        campaign_dropdown:{
            text_small:'błąd',
            text_big: 'Nie można wczytać <br/> wszystkich kampani',
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        },
        create_new_order_variant:{
            text_small:'błąd',
            text_big: 'Wystąpił problem przy <br/> tworzeniu nowego wariantu<br/> spróbuj ponownie',
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        },
        create_new_order:{
            text_small:'błąd',
            text_big: 'Wystąpił problem przy <br/> tworzeniu nowego zamówienia <br/> spróbuj ponownie',
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        },
        send_file:{
            text_small:'wystąpił błąd',
            text_big: 'nie można przesłać pliku,<br/> spróbuj ponownie',
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        },
        correction_list:{
            text_small:'błąd',
            text_big: `Nie można wczytać <br/> listy korekt`,
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        },
        accept_button:{
            text_small:'błąd',
            text_big: 'Nie można <br/> zaakceptować zlecenia <br/> spróbuj ponownie',
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        },
        add_counter:{
            text_small:'błąd',
            text_big: 'Nie można dodać licznika, <br/> spróbuj ponownie',
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        },
        take_over_error: {
            text_center: 'Nie można przejąć projektu, spróbuj ponownie',
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        },
        load_error: {
            text_small:'błędne wczytywanie danych',
            text_big: 'Wystąpił problem <br /> spróbuj ponownie <br /> później*',
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        },
        product_list: {
            text_small:'błędne wczytywanie danych',
            text_big: 'Nie można wczytać <br /> listy produktów',
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        },
        date:{
            text_small:'błąd',
            text_big: 'Proszę <br/> uzupełnić  <br/> okres',
            button_first_text: 'Ok',
            text_last: ""
        },
        method:{
            text_small:'błąd',
            text_big: 'Proszę <br/> uzupełnić  <br/> rodzaj',
            button_first_text: 'Ok',
            text_last: ""
        },
        default: {
            text_small:'wprowadź brakujące rekordy',
            text_big: 'Aby wykonać <br/> akcję uzupełnij <br/> zaznaczone pola*',
            button_first_text: 'Ok',
            text_last: "*zostały podświetlone na czerwono, jeżeli mają być pominięte — kliknij w górnym prawym rogu “◦ brak”."
        },
        proposal_deadline: {
            text_small:'uzupełnij prawidłowo wymagane pola',
            text_big: '"deadline na propozycje" <br/> nie może być późniejszy <br/> niż "Deadline" zlecenia',
            button_first_text: 'Ok',
            text_last: ""
        },
        history_varsion: {
            text_small:'błędne wczytywanie danych',
            text_big: 'Nie można wczytać <br /> specyfikacji e-commerce',
            button_first_text: 'Ok',
            text_last: "*jeżeli problem będzie wciąż występował skontaktuj się z nami: <strong>e-commerce@app4ad.pl</strong>"
        }
    },
};
