import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackgroundShadow from "../../../globalElements/BackgroundShadow";
import { ActiveCorrectionPopupPopupsContext } from "../collapseElements/CollapseRow";
import { MainAppContext } from "../../../../MainAppComponent";

const Correction = ({ banner, data, orderID }) => {
  const { isShadowActive, setShadowActive } = useContext(MainAppContext);
  const { activeCorrectionPopup, setActiveCorrectionPopup } = useContext(ActiveCorrectionPopupPopupsContext);
  const [localDisplayData, setLocalDisplayData] = useState([...data]);

  const navigate = useNavigate();
  

  const handleEdit = () => {
    setActiveCorrectionPopup(false);
    setShadowActive(false);
    let link = `/addneworder/?edit_order=true&order=${orderID}&is_correction=true`;
    navigate(link);
  };

  return (
    <>
      <div className="correction-inputs-fields">
        <div className="inputs-fields">
          {localDisplayData
            ? localDisplayData.map((el, index) => {
                return (
                  <div className="input-box" onClick={handleEdit} key={`correction-${index}`}>
                    <label key={`input-box-${index}`}>
                      <span className="name">{el.name}</span>
                      {typeof el.value == "object" 
                        ?<span>{el.value}</span>
                        :<span dangerouslySetInnerHTML={{ __html: el.value }}></span>
                      }
                    </label>
                    <div className="hint">
                      <span>kliknij aby</span>
                      <span>edytowac</span>
                      <div className="arrow"></div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        <div className="banner">
          <figure>
            <img src={banner}></img>
          </figure>
        </div>
      </div>
      <BackgroundShadow setShow={setActiveCorrectionPopup} handleShadow={true}/>
    </>
  );
};

export default Correction;
