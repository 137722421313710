import React from 'react';
import {ReactComponent as Delete}  from "../../../../../assets/popups/error.svg"
import {useNavigate} from "react-router-dom";
import usePopup from "../../../../helper/usePopup";
import 'react-toastify/dist/ReactToastify.css';
import {Bounce, toast} from "react-toastify";
const CancelCreating = () => {
    const navigate = useNavigate()
    const { closeOrderPopup } = usePopup();
    const handleFirstButton = () => {
        closeOrderPopup();
        navigate("/")
        toast.success("Pomyślnie przerwano realizację", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            icon:false,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
        })
    };
    const handleSecondButton = () => {
        closeOrderPopup();
    };
    return (
        <div className='abort-popup'>
            <div className="upper-part-popup">
                <Delete />
                <h3>Przerwij tworzenie<br/> zlecenia.</h3>
                <p>Tej operacji nie można cofnąć.</p>
            </div>
            <div className='buttons'>
                <button className="button cancel" onClick={handleSecondButton}>Anuluj</button>
                <button className="button abort" onClick={handleFirstButton}>Przerwij</button>
            </div>
        </div>
    );
}

export default CancelCreating;
