import React, { useState } from "react";
import { Collapse } from "@mui/material";

const SpecificationBox = ({ data }) => {
  const [activeCollapse, setActiveCollapse] = useState(false);

  const handleCollapse = () => {
    setActiveCollapse(!activeCollapse);
  };

  return (
    <div className="box">
      <div className="title-section">
        <h2>Aktualizacja {data.title}</h2>
        <button className="collapse-button" onClick={handleCollapse}>{activeCollapse ? "Zwiń" : "Rozwiń"}</button>
      </div>
      <Collapse in={activeCollapse} timeout="auto" unmountOnExit> 
        <p className="collapse-content" dangerouslySetInnerHTML={{ __html: data.description.replace(/\n/g, "<br/>") }}></p>
      </Collapse>
    </div>
  );
};

export default SpecificationBox;
