import React, { useState, useEffect, useContext, useRef, useReducer, createContext } from "react";
import { useSearchParams } from "react-router-dom";
import Table from "./mainelements/Table";
import Pagination from "./mainelements/Pagination";
import TableActions from "./mainelements/TableActions";
import orderServices from "../../../services/orderServices";
import notificationServices from "../../../services/notificationServices";
import Loader from "../../globalElements/Loader/Loader";
import LOADER_TYPE from "../../globalElements/Loader/LOADER_TYPE";
import usePopup from "../../../helper/usePopup";
import settingsService from "../../../services/settingsService";
import TABLE_TYPES from "../../../../globalEnums/TABLE_TYPES_ENUMS";
import checkPermissions from "../../../../functions/checkPermissions";
import PERMISSIONS_ENUMS from "../../../../globalEnums/PERMISSIONS_ENUMS";
import { UserContext } from "../../../wrapper/AuthWrap";
import {ReloadTableContext, TableDataContext} from "../../../Router";
import ErrorLoadingData from "../Popup/AddOrder/ErrorLoadingData";
import { ORDER_DATA_PARAMETERS_ACTION_TYPE,  orderDataParametersReducer, initializeOrderDataParametersFromUrl} from "./OrderDataParameterReducer/orderDataParametersReducer";
import { getRelevantParams } from "./OrderDataParameterReducer/orderDataParametersReducerHelpers";

export const OrderDataParametersContext = createContext(null);

/*Static structure for notification table START*/
let clientNotificationStructure = [
  {id: 1, column_title: "Nazwa akcji", defined_field: 'action_name', is_sortable: true, width: 20},
  {id: 2, column_title: "Otrzymane", defined_field: 'created_at', is_sortable: true, width: 20},
  {id: 3, column_title: 'Etap', defined_field: 'action', is_sortable: true, width: 20},
  {id: 3, column_title: 'Kto', defined_field: 'created_by', is_sortable: false, width: 20},
  {id: 3, column_title: 'Status Powiadomienia', defined_field: 'is_read', is_sortable: false, width: 20},
]

let adminNotificationStructure = [
  {id: 1, column_title: "Nazwa akcji", defined_field: 'action_name', is_sortable: true, width: 20},
  {id: 2, column_title: "Otrzymane", defined_field: 'created_at', is_sortable: true, width: 20},
  {id: 3, column_title: 'Etap', defined_field: 'action', is_sortable: true, width: 20},
  {id: 3, column_title: 'Kto', defined_field: 'created_by', is_sortable: false, width: 20},
  {id: 3, column_title: 'Przydzielone do działu', defined_field: 'department', is_sortable: false, width: 20},
  {id: 3, column_title: 'Status Powiadomienia', defined_field: 'is_read', is_sortable: false, width: 20},
]
/*Static structure for notification table END*/

const TableContextContainer = ({ tableType,pageTitle}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { tableContext, setTableContext} = useContext(TableDataContext)
  const { reloadComponent, setReloadComponent } = useContext(ReloadTableContext)
  const { user, setUser } = useContext(UserContext);
  const [loadingPageContent, setLoadingPageContent] = useState(true);
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [showErrorPopup, setErrorPopup] = useState(false);
  const [activeReloadAnimation, setActiveReloadAnimation] = useState(false);

  const [orderDataParameters, setOrderDataParameters] = useReducer(orderDataParametersReducer, {
    status: tableType,
    page_size: 20,
    page: 1,
    search: "",
    ordering: [],
    order_id: null,
    external_endpoint: null,
    variant_id: null,
    variant_index: 0
  }, initializeOrderDataParametersFromUrl);

  const {initPopup } = usePopup();

  const countWidthProportions = (valuesArray) =>{
    //in case when sum of cels width is less than 100
    let allFieldsWidthSum = 0;

    valuesArray.forEach((value) => {
      allFieldsWidthSum+= value.width; //width is percentage value
    })

    if(allFieldsWidthSum < 100){
      let additionalValue = (100 - allFieldsWidthSum) / valuesArray.length;

      return valuesArray.map(el=>{
        return {...el, width: el.width + additionalValue};
      })
    }

    return [...valuesArray];
  }

  const initTableConfiguration = async () =>{
    try{
      let response = await settingsService.getTablesConfiguration();

      if(Object.keys(response.client_table_config).length == 0){
        return null
      }

      switch(tableType){
        case TABLE_TYPES.IN_PROGRESS:
          return countWidthProportions(response.client_table_config.inprogress) //[...response.client_table_config.inprogress]
        case TABLE_TYPES.FINISHED:
          return countWidthProportions(response.client_table_config.archive) //[...response.client_table_config.archive]
        case TABLE_TYPES.DRAFT:
          return countWidthProportions(response.client_table_config.drafts) // [...response.client_table_config.drafts]
        case TABLE_TYPES.NEW:
          return countWidthProportions(response.client_table_config.sent) // [...response.client_table_config.sent]
          case TABLE_TYPES.NOTIFICATION:
          return checkPermissions(user, [PERMISSIONS_ENUMS.PERMISSIONS_NAMES.ECOMERCE_ORDER_CREATE]) ? clientNotificationStructure : adminNotificationStructure;
      }
    }catch(error){
      console.log('Table configuration error');
      console.error(error);
      return null
    }
  }

  const initData = async () => {
    let configuration = await initTableConfiguration();
    if(loadingPageContent == false) setLoadingTableData(true);
    if(configuration){
      const {status, page, page_size, external_endpoint, search, ordering} = orderDataParameters;
      if (tableType == "notification") {
        notificationServices
          .getNotifications(page, page_size, external_endpoint, search, ordering) //tableType, page, tableContext.postPerPage
          .then((res) => {
            setTableContext({ ...tableContext, tableType: tableType, tableData: res.results, staticTableData: res.results, tableConfiguration: configuration });
            if(loadingPageContent) setLoadingPageContent(false);
            if (activeReloadAnimation) setActiveReloadAnimation(false);
            if(loadingPageContent == false) setLoadingTableData(false);
          })
          .catch((error) => {
            console.error(error);
            initPopup(<ErrorLoadingData type={'negative'} info={'load_data'} setErrorPopup={setErrorPopup}/>)
            setErrorPopup(true);
            if(loadingPageContent) setLoadingPageContent(false);
            if (activeReloadAnimation) setActiveReloadAnimation(false);
            if(loadingPageContent == false) setLoadingTableData(false);
          });
      } else {        
        orderServices
          .getTableOrderList(status, page, page_size, external_endpoint, search, ordering)
          .then((res) => {
            let orders = [...res.results];
            orders = orders.sort((a, b) => {
              if (a.is_favourite === b.is_favourite) {
                return 0;
              }
              return a.is_favourite ? -1 : 1;
            });
            setTableContext({ ...tableContext, tableType: tableType, tableData: orders, staticTableData: orders, total_page: res.total_page, tableConfiguration: configuration });
            if(loadingPageContent) setLoadingPageContent(false);
            if (activeReloadAnimation) setActiveReloadAnimation(false);
            if(loadingPageContent == false) setLoadingTableData(false);
          })
          .catch((error) => {
            console.error(error);
            initPopup(<ErrorLoadingData type={'negative'} info={'load_data'} setErrorPopup={setErrorPopup}/>)
            setErrorPopup(true);
            if(loadingPageContent) setLoadingPageContent(false);
            if (activeReloadAnimation) setActiveReloadAnimation(false);
            if(loadingPageContent == false) setLoadingTableData(false);
          });
      }
    }else{
      setLoadingPageContent(false)
    }

  };

  useEffect(() => {
    if (reloadComponent) {
      initData();
      setReloadComponent(false);
    }
  }, [reloadComponent]);
  useEffect(() => {
    //handle pagination first, allows avoid double value fetch action
    const timer = setTimeout(() => {
      if(orderDataParameters.page){
        initData();
      }else{
        if(searchParams.get('pagination'))
          setOrderDataParameters({ type: ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_PAGINATION, page: parseInt(searchParams.get('pagination')) });
        else
          setOrderDataParameters({ type: ORDER_DATA_PARAMETERS_ACTION_TYPE.SET_PAGINATION, page: 1 });
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [JSON.stringify(getRelevantParams(orderDataParameters))]); //list of keys on which state change we want call useEffect

  const handleReloadTable = () => {
    setActiveReloadAnimation(true);
    setReloadComponent(true);
  };

  return (
    <OrderDataParametersContext.Provider value={{orderDataParameters, setOrderDataParameters}}>
      {!showErrorPopup && (
        <div className="table-context">
          <div className="wrapper">
            <div className="title">
              <button className="btn btn-rounded btn-default refresh-button" onClick={handleReloadTable}>
                Odśwież
              </button>
              <h2 id="pageTitle">{pageTitle}</h2>
            </div>
            <TableActions setLoadingTableData={setLoadingTableData} />
            {loadingPageContent ? (
              <Loader type={LOADER_TYPE.LOAD_DATA} width={25} height={25} position={`absolute`} startAnimation={true} />
            ) : tableContext.tableConfiguration.length > 0 ? (
              <>
                <Table loadingTableData={loadingTableData} orderDataParameters={orderDataParameters} setOrderDataParameters={setOrderDataParameters}/>
                <Pagination initData={initData} orderDataParameters={orderDataParameters} setOrderDataParameters={setOrderDataParameters}/>
              </>
            ) : (
              <div className="no-configuration-info">
                <span>tabela nie została skonfigurowana</span>
              </div>
            )}
          </div>
        </div>
      )}
    </OrderDataParametersContext.Provider>
  );
};

export default TableContextContainer;
